@charset 'utf-8';
/** Common **/
body {
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
  }
}

main {
  flex: 1 0 auto;
  min-height: 0%;/* for IE11 */
}

svg, img {
  max-width: 100%;
  height: auto;
}

::-webkit-input-placeholder {
  font-size: $small-font-size;
}

:-ms-input-placeholder {
  font-size: $small-font-size;
}

::placeholder {
  font-size: $small-font-size;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

i, address {
  font-style: normal;
}

.theme-dd-rounded-bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

pre {
  font-family: $font-family-base;
  font-size: inherit;
}

@mixin icon-rounded($bg, $color) {
  background-color: $bg;
  .theme-dd-icon {
    fill: $color;
  }
}

/** Header **/
.theme-dd-header {
  font-weight: $font-weight-bold;

  &.navbar-dark .navbar-nav .nav-link {
    color: $white;
  }

  .navbar-brand {
    width: 40%;
    svg {
      width: 100%;
      height: auto;
      fill: currentColor;
      max-height: 10vh;
      transition: all .4s ease-out;
    }

    &.is-smaller {
      svg {
        width: 65%;
      }
    }
  }
  
  .navbar-toggler {
    width: 56px;
    height: 40px;

    &[aria-expanded='false'] .navbar-toggler-bar {
      transform: none;
      &:nth-child(1) {
        top: 10px;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        bottom: 10px;
      }
    }

    &[aria-expanded='true'] .navbar-toggler-bar {
      &:nth-child(1) {
        top: 17px;
        transform: rotate(-45deg) translateX(-1.5px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        bottom: 17px;
        transform: rotate(45deg) translateX(-1.5px);
      }
    }
  }

  .navbar-toggler-bar {
    width: 1.5em;
    background-color: hsla(0,0%,100%,.5);
    height: 2px;
    display: block;
    position: absolute;
    left: 13px;
    border-radius: $border-radius;
    transition: all 0.6s ease;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    height: $pc-header-height;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: height .4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    &.is-smaller {
      height: $pc-header-small-height;
    }
    
    .navbar-brand {
      width: 238px;
      max-width: 19%;
      svg {
        max-height: none;
        height: 61px;
      }
    }

    .collapse.navbar-nav.mr-auto {
      min-width: 420px;
    }

    &.navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
      position: relative;
      &::after {
        width: 90%;
        margin: 0 auto;
        background-color: rgba($white, 0.5);
        height: 2px;
        content: "";
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        transform: translateY(0);
        transition: all 0.3s;
      }
      &:hover::after {
        opacity: 1;
        transform: translateY(-5px);
      }
    }
    .nav-link {
      font-size: $font-size-sm;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    &.navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .nav-link {
      font-size: inherit;
    }
    .collapse.navbar-nav.mr-auto {
      min-width: 530px;
    }
  }
}

.theme-dd-login-wrap {
  margin-top: $spacer;
  padding-top: map-get($spacers, 2);
  position: relative;

  &::before {
    width: 100%;
    background-color: darken($primary, 5%); 
    content: "";
    height: 1px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .btn {
    font-weight: $font-weight-bold;
    padding-top: $input-btn-padding-y;
    padding-bottom: $input-btn-padding-y;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    min-width: 200px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;

    &::before {
      display: none;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
    max-width: 370px;//For IE11
  }
}

/** Footer **/
.theme-dd-footer {
  background-color: $footer-color;
  color: $white;

  a {
    color: $white;
  }

  &-logo {
    svg {
      max-width: 100%;
      fill: $footer-logo-color;
    }
  }

  &-bottom {
    border-top: solid 1px rgba($white, 0.175);
    text-align: center;
  }

  .list-group-item {
    background-color: transparent;
    border-color: rgba($white, 0.175);
    a {
      width: 100%;
      display: inline-block;
      position: relative;
      &::after {
        border-left: solid 0.5em $white;
        border-top: solid 0.3em transparent;
        border-bottom: solid 0.3em transparent;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -0.5em;
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &-logo {
      width: 198px;
      svg {
        height: 50px;
      }
    }

    .list-group {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .list-group-item {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border: none;
      a {
        &::after {
          border: none;
        }
      }
      & + .list-group-item a::after {
        content: "｜";
        right: auto;
        left: -1.25em;
      }
    }

    &-bottom {
      > small {
        padding-bottom: 0.75rem;
      }
    }
  }
}

/** Container **/
.theme-dd-container {
  padding-top: map-get($spacers, 2 );
}

@media print, screen and (min-width: map-get($grid-breakpoints, md)) {

  .theme-dd-container {
    max-width: 1100px;
    padding-top: calc($pc-header-height + 1.5rem);
  }

  .theme-dd-right {
    max-width: $right-area-w;

    .theme-dd-ad-wrap {
      min-width: 300px;
      width: 100%;
    }
  }

  .theme-dd-left {
    width: calc(100% - $right-area-w);
  }
}

@media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
  .theme-dd-left {
    max-width: 728px;
  }
}

/** Upload **/
.theme-dd-upload-size {
  &.nav-tabs {
    border-bottom: none;
    .nav-link.active {
      background-color: $white;
    }
  }

  .nav-item {
    width: 50%;
  }

  .nav-bnr {
    padding-bottom: map-get($spacers, 2);
    flex-basis: 100%;
    flex-grow: 1;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    gap: 0.25rem;

    .nav-item {
      width: 36%;
      max-width: 220px;
    }

    .nav-bnr {
      padding-bottom: map-get($spacers, 1);
      max-width: 216px;
      flex-basis: 25%;
    }
  }
}



@media all and (-ms-high-contrast: none) {//For IE11
  .theme-dd-upload-area .theme-dd-upload-files > div > p {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.theme-dd-upload-area {
  background-color: lighten(desaturate(adjust-hue($primary, 7), 12.17), 61.57);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  min-height: 80px;
  height: auto;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .theme-dd-upload-files {
    & > div {
      width: 25%;
      line-height: 1.3;
      font-size: $font-size-base * 0.625;

      & > p {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .badge {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    min-height: 160px;
    
    .theme-dd-upload-files {
      & > div {
        width: 10%;
        min-width: 4rem;
      }
    }
  }
}

.theme-dd-file-select {
  display: inline-block;
  &:hover {
    .is-file-select {
      background-color: $gray-100;
      border-color: $gray-100;
    }
  }

  .is-file-select {
    background-color: $white;
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .custom-file-input {
    cursor: pointer;
  }
}

.theme-dd-progress-circle {
  $size: 7rem;
  width: $size;
  background-color: $secondary;
  height: $size;
  position: relative;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 3px $gray-500);
  overflow: hidden;
  transform: translateZ(0);
  
  &::before, &::after {
    width: $size;
    background-color: $gray-200;
    content: "";
    position: absolute;
    top: 0;
    display: block;
    height: $size;
  }
  &::before {
    left: -$size * 0.5;
    transform-origin: right $size * 0.5;
    z-index: 2;
  }
  &::after {
    right: -$size * 0.5;
    transform-origin: left $size * 0.5;
    z-index: 3;
  }
  &-inner {
    width: $size * 0.8;
    background-color: $white;
    height: $size * 0.8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    filter: drop-shadow(0px 0px 2px $gray-300);
    &::before {
      width: $size * 0.6;
      background-color: $secondary;
      height: $size * 0.6;
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      filter: drop-shadow(0px 0px 2px $gray-600);
      z-index: -1;
    }
  }
  @for $i from 0 to 101 {
    @if ($i < 51) {
      $deg: $i * 3.6;
      &.percent-#{$i}::after { 
        transform: rotate($deg * 1deg);
      }
    }
    @else {
      $deg: ($i - 50) * 3.6;
      &.percent-#{$i}::before { 
        transform: rotate($deg * 1deg);
      }
      &.percent-#{$i}::after {
        transform: rotate(360deg);
        background-color: $secondary;
      } 
    }
  }

  @media print, screen and (max-width: 320px ) { //For iPhone SE
    $size: 6rem;
    &, &::before, &::after { 
      width: $size;
      height: $size;
    }
    &::before {
      left: -$size * 0.5;
      transform-origin: right $size * 0.5;
    }
    &::after {
      right: -$size * 0.5;
      transform-origin: left $size * 0.5;
    }
    &-inner {
      width: $size * 0.8;
      height: $size * 0.8;
      &::before {
        width: $size * 0.6;
        height: $size * 0.6;
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    $size: 10rem;
    &, &::before, &::after { 
      width: $size;
      height: $size;
    }
    &::before {
      left: -$size * 0.5;
      transform-origin: right $size * 0.5;
    }
    &::after {
      right: -$size * 0.5;
      transform-origin: left $size * 0.5;
    }
    &-inner {
      width: $size * 0.8;
      height: $size * 0.8;
      &::before {
        width: $size * 0.6;
        height: $size * 0.6;
      }
    }
  }
}

.theme-dd-progress-bar-wrap {
  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $gray-200;
    border-radius: $rounded-pill;
    filter: drop-shadow(0 1px 1px $gray-500);
  }
  ::-webkit-progress-bar {
    background-color: $gray-200;
    border-radius: $rounded-pill;
  }
  ::-webkit-progress-value {
    background-color: $secondary;
    border-radius: $rounded-pill;
  }
  ::-moz-progress-bar {
    background-color: $secondary;
  }
}

.theme-dd-tabs {
  display: flex;
  align-items: stretch;

  .nav-item {
    flex: 0 0 49%;
    max-width: 49%;

    & + .nav-item {
      margin-left: 2%;
    }
  }
  .nav-link {
    padding: 0.75rem 0;
    color: $secondary;
    text-align: center;
    font-weight: 700;
    box-shadow: 0.1rem 0 0.1rem rgba($black, .15);
    border-radius: $btn-border-radius;
    height: 100%;
    &, &.active, &.disabled, &:hover {
      border: solid 1px $gray-400;
    }
  }
  .nav-link.active {
    background-color: $secondary;
    color: $white;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    .nav-item {
      &.is-short {
        flex: 0 0 35%;
        max-width: 35%;

        & + .nav-item {
          margin-left: 2rem;
        }
      }
    }
    .nav-link {
      padding: 0.75rem 1rem;
    }
  }
}

.theme-dd-upload-tab-content {
  &, .form-control {
    font-size: $font-size-sm;
  }
}

.has-ads {
  .col-form-label {
    @extend .col-md-3;
    & + .col-md-10 {
      @extend .col-md-9;
    }
  }

  .theme-dd-btns-wrap {
    .col-lg-2 {
      @extend .col-md-3;
    }

    .col-lg-3 {
      @extend .col-md-4;
    }
  }

  .theme-dd-table-head {
    .col-2 {
      @extend .col-3;
    }
  }
}

/** Information(iframe) **/
.theme-dd-information {
  // position: relative;
  // min-height: 180px;
  // &::before {
  //   width: 100%;
  //   background-color: rgba($white, 0.5);
  //   position: absolute;
  //   content: "Loading Contents…";
  //   color: $gray-500;
  //   font-family: $font-family-monospace;
  //   font-weight: $font-weight-bolder;
  //   z-index: 1;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  // }
  iframe {
    width: 100%;
    border: none;
    // position: relative;
    // z-index: 2;
  }

  // @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
  //   min-height: auto;
  // }
}

/** Ad **/
.theme-dd-ad-fixed {
  width: 320px;
  height: 50px;
}

.theme-dd-ad-wrap {
  &.is-pc {
    div[align='left'] {
      display: none;
    }
  }
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.is-pc {
      div[align='left'] {
        display: block;
      }
    }
    &.is-upload-middle {
      max-width: 100%;
    }
    @at-root .theme-dd-right .theme-dd-ad-wrap div[id*='div-gpt-ad'] {
      width: 300px;
    } 
  }
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    &.is-upload-middle {
      max-width: 728px;
      margin-left: -1.25rem;
    }
    @at-root .theme-dd-right .theme-dd-ad-wrap div[id*='div-gpt-ad'] {
      width: 336px;
    }
  }
}

.theme-dd-ads-bg {
  position: relative;
  background-color: rgba($white, 0.5);
  &::before {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1em;
    content: "Loading Ads…";
    color: $gray-500;
    font-family: $font-family-monospace;
    font-weight: $font-weight-bolder;
  }
}

/** Register **/
.theme-dd-or-text {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;

  &::before, &::after {
    background-color: $border-color;
    flex: 1 1 auto;
    height: 1px;
    content: "";
  }

  &::before {
    margin-right: map-get($spacers, 2);
  }

  &::after {
    margin-left: map-get($spacers, 2);
  }

  & + div {
    border-bottom: $border-width solid $border-color;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    width: auto;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: block;

    &::before, &::after {
      display: none;
    }

    & + div {
      border-left: $border-width solid $border-color;
      border-bottom: none;
    }
  }
}

.theme-dd-card {
  &.is-disabled {
    filter: grayscale(100%);
  }
}

/** Mypage **/
.theme-dd-mypage-nav {
  .nav-link {
    padding: 0.5rem;
    text-align: center;
    background-color: $info;
    color: $white;
    box-shadow: 1.5px 1.5px 2px rgba($black, 0.3);
    font-size: $h6-font-size;
    white-space: nowrap;

    &:nth-child(2) {
      margin-left: 0.25rem;
    } 

    &.active {
      background-color: $blue;
    }

    .icon-lock {
      display: none;
    }

    &.is-disabled {
      position: relative;
      &::before {
        width: 100%;
        content: "";
        background-color: rgba($gray-800, 0.8);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius;
        z-index: 2;
      }
      .icon-lock {
        width: 2rem;
        height: 2rem;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }
    }

    &.is-lite {
      pointer-events: none;
    }
  }

  a.nav-link, span.nav-link.is-disabled {
    font-weight: $font-weight-bold;
  }

  a.nav-link:not(.active):hover {
    background-color: darken($info, 10%);
  }

  span.nav-link:not(.is-disabled) {
    background-color: $white;
    border: $card-border-width solid $card-border-color;
    color: $gray-800;
    box-shadow: $box-shadow-sm;
  }

  .position-absolute.w-100.h-100 {
    z-index: 4;
  }

  &.is-business {
    @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
      flex-wrap: nowrap;
      .nav-link {
        height: 56px;
        
        + .nav-link {
          margin-left: 1rem;
        } 
      }
  
      span.nav-link:not(.is-disabled) {
        white-space: nowrap;
      }
    }
  }

  @media print, screen and (min-width: 414px) {
    .nav-link:nth-child(3) {
      margin-left: 0.25rem;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    .theme-dd-icon-wrap.is-rounded {
      width: 2rem;
      height: 2rem;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: nowrap;
    .nav-link {
      padding: 0rem 1rem;
      height: 56px;
      font-size: $h5-font-size;
      + .nav-link {
        margin-left: 1rem;
      } 
    }

    span.nav-link:not(.is-disabled) {
      white-space: nowrap;
    }
  }

}

.theme-dd-balloon {
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow;
  bottom: 100%;
  left: 50%;
  color: $gray-900;
  font-weight: $font-weight-normal;
  z-index: 3;
  transform: translateX(-50%);
  white-space: normal;

  &::before {
    border-top: solid 10px $white;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    content: "";
    z-index: 2; 
    position: absolute;
    top: 100%;
    left: 50%;
    filter: drop-shadow($box-shadow);
    transform: translateX(-50%);
  }

  &.is-horizontally {
    width: auto;
    left: 100%;
    bottom: auto;
    top: 50%;
    transform: translate(10px, -50%);

    &::before {
      border-top-color: transparent;
      border-left-color: $white;
      left: 0;
      top: 50%;
      transform: translate(-5px, 0) rotate(45deg);
    }
  }

  > .theme-dd-icon.is-gray {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    width: auto;
    min-width: 14rem;
  }
}

.theme-dd-mypage-menu {
  border-bottom: $border-width solid $border-color;
  border-left: $border-width solid $border-color;

  &:nth-of-type(odd) {
    border-left: none;
  }

  &:nth-of-type(n +7) {
    border-bottom: none;
  }

  &.is-disabled {
    position: relative;
    .theme-dd-icon {
      filter: grayscale(1);
    }
    &::before {
      width: 100%;
      background-color: rgba($white, 0.8);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  & > a {
    display: block;
    text-align: center;
    color: $gray-900;
    transition: 0.3s ease;

    &:hover, &:focus {
      color: $secondary;
      text-decoration: none;
    }
  }

  .theme-dd-credit-announce {
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    & + .theme-dd-mypage-menu {
      border-left: $border-width solid $border-color;
    }

    &:nth-of-type(5) {
      border-left: none;
    }
  
    &:nth-of-type(n + 5) {
      border-bottom: none;
    }
  }
}

.theme-dd-file-icon { 
  width: $icon-size-sp;

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    width: $icon-size-pc;
  }
}

/** Proguress tracker **/
.theme-dd-steps {
  padding-left: 0;
  position: relative;

  li {
    text-align: center;
    position: relative;
    list-style: none;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: $gray-600;

    &::before {
      width: 3.75rem;
      background-color: $white;
      border: solid 4px $white;
      margin-bottom: 0.5rem;
      border-radius: 50%;
      display: block;
      height: 3.75rem;
      font-size: $h3-font-size;
      line-height: calc(3.75rem - 2px);
      @extend .theme-dd-shadow;
      color: currentColor;
    }
    
    &.is-current {
      color: $secondary;
      &::before {
        border-color: $secondary;
      }
      &::after {
        background-color: $secondary;
      }
    }

    &.is-prev {
      color: $gray-800;
      &::before {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
      &::after {
        background-color: $secondary;
      }
    }

    & + li::after {
      width: 100%;
      background-color: $white;
      height: 0.75rem;
      content: "";
      position: absolute;
      top: 1.25rem;
      right: 50%;
      z-index: -1;
      box-shadow: $box-shadow-sm;
    }
  }
  @for $i from 1 through 4 {
    li:nth-of-type(#{$i})::before {
      content: "#{$i}";
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    min-width: 50%;
    margin: 0 auto;
  }
}

/** Scroll **/
.theme-dd-scroll-wrap {
  max-height: 8rem;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-lg {
    max-height: 20rem;
  }

  &.is-post {
    max-height: 30rem;
  }

  &.is-terms {
    max-height: 10rem;
    h1, h2 {
      font-size: 90%;
    }

    p, li {
      font-size: 86%;
    }
  }

  &.is-horizontal-scrollable {
    overflow-x: auto;
    max-height: 15rem;
  }

  &::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
  }
  &::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    background: $gray-400;
    border-radius: $border-radius-lg;
  }
  &::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: $border-radius-lg;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1.0;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.is-terms {
      max-height: 310px;
      h1 {
        font-size: $h6-font-size;
      }
      h2 {
        font-size: $font-size-sm;
      }
      p, li {
        font-size: inherit;
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.hide-scroll {
      height: auto;
      max-height: none;
    }

    &.is-address {
      max-height: 650px;
    }
  }
}

.theme-dd-scroll-image {
  width: .75rem;
  position: absolute;
  right: .75rem;
  bottom: .5rem;
  animation: fadeIn 1.2s .6s ease-in-out;
  display: none;
}

/** Text **/
.theme-dd-h1 {
  font-size: $font-size-base * 1.125;
  font-weight: $font-weight-bold;
  color: $indigo;
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    font-size: $h3-font-size;
  }
}

.theme-dd-large {
  font-size: $font-size-base;

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    font-size: $font-size-base * 1.125;
  }
}

.theme-dd-small {
  font-size: $font-size-sm;
}

.theme-dd-xsmall {
  font-size: $font-size-base * .775;
}

/** Table, List **/
.theme-dd-sort-head {
  a {
    color: $gray-900;
  }
}

.table td[data-head],
div[data-head] {
  word-break: break-all;

  .btn {
    width: 100%;
    @extend .btn-sm;
  }

  &.has-files {
    display: flex;
  }
  
  &.has-line-break {
    flex-wrap: wrap;
  }
  
  &.has-progress {
    .text-truncate {
      margin-left: 7.25rem;
    }
  }

  &.has-url {
    &::before {
      min-width: auto;
      flex-basis: 100%;
    }
  }

  @media print, screen and (max-width: 769px) {
    & {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      border-top: none;

      &::before {
        min-width: 7rem; 
        margin-right: 0.25rem;
        content: attr(data-head);
        font-weight: $font-weight-bold;
      }
    }

    &.has-files {
      flex-wrap: wrap;
      &::before {
        min-width: auto;
        flex: 0 0 100%;
        display: block;
      }
  
      & > *:last-child {
        width: calc(100% - ($icon-size-sp + 0.5rem));
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    .btn {
      width: auto;
      @include button-size($btn-padding-y-sm, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    }

    &.has-files {
      &[data-head="ファイル名"] {
        flex-grow: 1;
      }
      
      & > *:last-child {
        max-width: calc(100% - ($icon-size-pc + 0.5rem));
      }
    }

    &.has-progress .text-truncate {
       margin-left: 0;
    }
  }
}

.table td[data-head] {
  &:first-of-type {
    padding-top: 0.75rem;
    border-top: solid 1px $gray-300;
  }

  &:last-of-type {
    padding-bottom: 0.75rem !important;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    border-top: solid 1px $gray-300;
    display: table-cell;

    &.has-files {
      & > *:last-child {
        width: auto;
      }
    }
  }
}

td[data-btn] {
  display: block;
  border-top: none;

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    display: table-cell;
    border-top: $table-border-width solid $table-border-color;
  }
}

.theme-dd-striped {
  .bg-light > tr > th {
    font-size: $font-size-sm;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    .bg-light > tr > th {
      font-size: $font-size-base;
    }

    .bg-light.d-none {
      display: table-header-group !important;
    }
  }
}

// .theme-dd-files-list-row,
// .theme-dd-directory-list,
// .theme-dd-files-list {
//   &.is-root + .theme-dd-files-list-row.is-root,
//   &.is-root + .theme-dd-directory-list.is-root {
//     border-top: solid 1px $gray-300;
//   }
// }

.theme-dd-striped > div.is-root:nth-of-type(even) {
  border-top: solid 1px $gray-300;
  border-bottom: solid 1px $gray-300;
}

.theme-dd-files-list {
  &-header {
    display: flex;
    align-items: center;
  }

  &-row {
    @extend .theme-dd-small;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  } 

  @media print, screen and (max-width: 769px) {
    &, &-row {
      .theme-dd-file-name-wrap ~ div {
        padding-left: calc(24px + 0.5rem);
      }
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.is-root + .theme-dd-directory-list {
      min-width: 100%;
    }
    
    &-row {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;

      & > div:last-child {
        flex-shrink: 1;
      }
    }

    .theme-dd-file-name-wrap {
      padding-right: 1rem;
    }
  }
}

@media print, screen and (min-width: map-get($grid-breakpoints, md)) {
  .theme-dd-directory-list {
    &.is-root {
      padding-right: 15px;
      width: max-content;
      min-width: 100%;
    }
    .theme-dd-directory-info .text-break {
      max-width: 24em;
    }
  }

  .theme-dd-directory-list, .theme-dd-files-list, .theme-dd-files-list-row {
    &.is-root {
      padding-left: 1rem;
    }
  }

  .theme-dd-directory-list > .theme-dd-files-list > .theme-dd-files-list-row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: unset;
  }

  .theme-dd-directory-list > .theme-dd-files-list > .theme-dd-directory-list {
    padding-top: 0.5rem;
  }

  .theme-dd-directory-list > .theme-dd-files-list {
    padding-left: 2rem;
  }
}

.theme-dd-cancel-files {
  .theme-dd-file-icon {
    flex: 0 0 28px;
    max-width: 28px;

    & + * {
      flex-shrink: 1;
      word-wrap: break-word;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    .theme-dd-file-icon {
      flex: 0 0 auto;
      max-width: inherit;
    }
  }
}

/** Button **/
.btn {
  box-shadow: 1.5px 1.5px 2px rgba($black, 0.3);
  padding-top: $input-btn-padding-y-sm;
  padding-bottom: $input-btn-padding-y-sm;
  white-space: nowrap;

  &.is-google, &.is-facebook, &.is-apple {
    font-size: $font-size-sm;
    height: 44px;
    & > span {
      display: none;
    }
  }

  &.is-google {
    background: url(../images/common/g_logo.png) no-repeat center center / 60px 60px;
    &:hover {
      border-color: $gray-400;
    }
  }

  &.is-facebook {
    background: url(../images/common/f_logo.png) #3f61a5 no-repeat center center / 32px 32px;
    border-color: #3f61a5;
  }
  
  &.is-apple {
    background: url(../images/common/a_logo.png) $black no-repeat center center / 23px 28px;
    border-color: $black;
  }
  
  &.custom-radio {
    padding-left: 2rem;
    text-align: left;
  }

  &.is-checked {
    box-shadow: inset 1.5px 1.5px 2px rgba($black, 0.3);
  }

  &[class*='has-icon'] {
    position: relative;

    .theme-dd-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  
  &.has-icon {
    &-right .theme-dd-icon {
      right: 0.5rem;
    }
    &-center {
      min-width: 2.2rem !important;
      min-height: 2.2rem;

      .theme-dd-icon {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.is-wide {
    width: 100%;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &:not(.btn-block) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &:not(.btn-block):not(.is-password) {
      min-width: 6em;
    }

    &.is-wide {
      width: auto;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &.custom-radio {
      padding-left: 3rem;
    }
    
    &.is-google, &.is-facebook, &.is-apple {
      padding-left: 44px;
      font-size: $font-size-base;
      height: auto;
      & > span {
        display: inline-block;
      }
    }
  
    &.is-google {
      background: url(../images/common/g_logo.png) no-repeat left center / 44px 44px;
    }
  
    &.is-facebook {
      background: url(../images/common/f_logo.png) #3f61a5 no-repeat 0.5rem center / 28px 28px;
    }
    
    &.is-apple {
      background: url(../images/common/a_logo.png) $black no-repeat 0.5rem center / 20px 24px;
    }

    &.btn-lg {
      font-size: $font-size-base;
    }
  }
}

.btn-link {
  box-shadow: none;
}

.btn-primary {
  &.is-login {
    background-color: $secondary;
    border-color: $secondary;
    word-break: keep-all;
    &:hover, &:active, &:focus {
      background-color: darken($secondary, 7.5%);
      border-color: darken($secondary, 7.5%);
    }
  }
  
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.is-login {
      width: 8em;
    }
    &.btn-lg {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.btn-warning {
  color: $white;
}

.btn-outline-light {
  border-color: $gray-400;
  color: $gray-900;
  background-color: $white;

  &.is-password {
    padding: 0.25rem 0.45rem 0.25rem 0.15rem;
    box-shadow: none;
    border-left-color: transparent;
    &:hover, &:focus {
      background-color: transparent;
      border-top-color: $gray-400;
      border-right-color: $gray-400;
      border-bottom-color: $gray-400;
    }
  }
}

.theme-dd-operation-btns {
  .btn {
    @extend .btn-sm;
    &.has-icon-center {
      min-width: 1.9rem !important;
      min-height: 1.9rem !important;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    .btn {
      @include button-size($btn-padding-y-sm, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
      &.has-icon-center {
        min-width: 2.2rem !important;
        min-height: 2.2rem !important;
      }
    }
  }
}

/** Badges **/
.theme-dd-badge {
  min-width: 1.75em;
  padding: 0.4em;
  border-radius: 50%;
}

/** Icon **/
.theme-dd-icon-wrap {
  position: relative;
  .icon-meter, .icon-hourglass {
    width: 55px;
    fill: darken(desaturate(adjust-hue($secondary, 1), 25.69), 0.20);
    height: 55px;
    filter: drop-shadow(1px 1px 1px $gray-500);
  }

  .icon-meter {
    position: relative;
    z-index: 2;
  }
  
  .icon-needle {
    width: 0;
    border-top: solid 1px transparent;
    border-right: solid 13px darken(desaturate(adjust-hue($secondary, 1), 25.69), 0.20);
    border-bottom: solid 1px transparent;
    transform-origin: 12px 2.5px;
    position: absolute;
    height: 0;
    top: 25px;
    left: calc(50% - 13px);
    transform: rotate(-10deg);
    display: block;
    animation: needle 3s cubic-bezier(0, 0, 1.0, 1.0) infinite;
    z-index: 1;
  }

  @keyframes rotate {
    100% {
      transform: rotate(180deg);
    }
  }

  .rotated {
    animation: rotate 0.5s ease forwards;
  }

  &.is-rounded {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    .theme-dd-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    .icon-needle {
      border-right-width: 18px;
      border-top-width: 2px;
      border-bottom-width: 2px;
      transform-origin: 17px 3px;
      left: calc(50% - 18px);
    }
  }
}

.theme-dd-tabs .nav-link {
  &:not(.active) .is-rounded {
    @include icon-rounded($secondary, $white);
  }
  .is-rounded {
    @include icon-rounded($white, $secondary);
  }
}

.theme-dd-mypage-nav .nav-link {
  &:not(.active) .is-rounded {
    @include icon-rounded($white, $info);
  }
  .is-rounded {
    @include icon-rounded($white, $blue);
  }
}

.theme-dd-icon {
  $size: 1.25em;
  width: $size;
  height: $size;
  fill: currentColor;
  flex: 0 0 $size;

  &.is-secondary {
    fill: $secondary;
  }
  &.is-info {
    fill: $info;
  }
  &.is-teal {
    fill: $teal;
  }
  &.is-danger {
    fill: $danger;
  }
  &.is-gray {
    fill: $gray-500;
  }
  &.is-sm {
    width: 1rem;
    height: 1rem;
    flex: 0 0 1rem;
  }
  &.is-lg {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }
  &.is-xlg {
    width: 2.5rem;
    height: 2.5rem;
    flex: 0 0 2.5rem;
  }
  &.is-huge {
    width: 4.5rem;
    height: 4.5rem;
    flex: 0 0 4.5rem;
  }

  &.icon-chevron::before {
    width: 1rem;
    content: "＞";
    display: inline-block;
  }

  &.icon-prev::before,
  &.icon-next::before {
    content: "";
    border-top: solid 0.25rem transparent;
    border-bottom: solid 0.25rem transparent;
    display: inline-block;
  }

  &.icon-prev::before {
    border-right: solid 0.45rem $black;
  }

  &.icon-next::before {
    border-left: solid 0.45rem $black;
  }

  &.icon-sort {
    &, &-up, &-down {
      position: relative;

      &::before,
      &::after {
        content: "";
        border-left: solid 0.25rem transparent;
        border-right: solid 0.25rem transparent;
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
    
    &-up::before,
    &-down::before {
      top: 50%;
      transform: translateY(-50%);
    }

    &-up::before {
      border-bottom: solid 0.45rem $black;
    }

    &-down::before {
      border-top: solid 0.45rem $black;
    }
    
    &::before {
      border-bottom: solid 0.45rem $black;
      top: 0;
    }
    &::after {
      border-top: solid 0.45rem $black;
      bottom: 0;
    }
  }

  &.icon-chevron {
    &-down::before, &-right::before {
      width: 100%;
      content: "";
      mask-repeat: no-repeat;
      background-color: currentColor;
      display: inline-block;
      height: 100%;
    }
    &-down::before {
      mask-image: url("data:image/svg+xml,<svg viewBox='0 0 32 32' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16.036,25.891L0.703,10.558l4.6-4.601l10.733,11.5l10.733-11.5l4.6,4.601L16.036,25.891z' clip-rule='evenodd'/></svg>");
    }
    &-right::before {
      mask-image: url("data:image/svg+xml,<svg viewBox='0 0 32 32' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M26.003,15.924L10.67,31.257l-4.601-4.6l11.5-10.733L6.069,5.19l4.601-4.6L26.003,15.924z' clip-rule='evenodd'/></svg>");
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    &.is-huge {
      width: 6rem;
      height: 6rem;
      flex: 0 0 6rem;
    }
  }
}

[class*="icon-sort"].position-absolute {
  right: -0.5rem;
  bottom: -0.5rem;
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    position: inherit !important;
    right: inherit;
    bottom: inherit;
  }
}

@keyframes needle {
  0% {
    transform: rotate(-10deg);
    top: 25px;
  }
  25% {
    transform: rotate(90deg);
    top: 23px;
  }
  50% {
    transform: rotate(180deg);
    top: 23px;
  }
  75% {
    transform: rotate(90deg);
    top: 23px;
  }
  100% {
    transform: rotate(-10deg);
    top: 25px;
  }
}

@keyframes rotate-vertical {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}

.theme-dd-filebox-img {
  width: 7rem;
  height: 7rem;
  animation: rotate-vertical 4s infinite linear;
  transform-style: preserve-3d;

  .is-front, .is-back {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .is-back {
    transform: rotateY(180deg);
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    width: 160px;
    height: 155px;
  }
}

/** Form **/
.form-control:disabled, .form-control[readonly],
.form-control:disabled + .input-group-append,
.form-control:disabled + .input-group-append .btn-outline-light,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input.checked ~ .custom-control-label::before {
  background-color: $gray-100;
}

.input-group .form-control {
  height: auto;
}

.input-group:not(.has-validation) .form-control {
  border-right-color: transparent;
}

.input-group-append .btn-outline-light {
  min-width: auto !important;
  padding: 0.25rem 0.45rem 0.25rem 0.15rem;
  box-shadow: none;
  border-left-color: transparent;
  &:hover, &:focus {
    background-color: transparent;
    border-top-color: $gray-400;
    border-right-color: $gray-400;
    border-bottom-color: $gray-400;
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32"><path fill="%23c5705b" d="M16.227,0.133c-8.706,0-15.764,7.058-15.764,15.764S7.521,31.66,16.227,31.66S31.99,24.603,31.99,15.896 S24.933,0.133,16.227,0.133z M17.796,25.548c-0.427,0.426-0.949,0.638-1.569,0.638s-1.144-0.218-1.568-0.656 c-0.426-0.449-0.639-0.966-0.639-1.55c0-0.608,0.213-1.124,0.639-1.551c0.425-0.425,0.948-0.639,1.568-0.639 c0.632,0,1.156,0.207,1.569,0.621c0.425,0.425,0.639,0.948,0.639,1.568C18.434,24.599,18.221,25.123,17.796,25.548z M18.36,8.598 l-1.423,10.932c-0.049,0.45-0.285,0.676-0.711,0.676c-0.183,0-0.341-0.073-0.474-0.219c-0.135-0.146-0.213-0.298-0.238-0.456 L14.093,8.598c-0.025-0.219-0.037-0.491-0.037-0.82c0-0.535,0.183-1.027,0.548-1.479c0.365-0.461,0.905-0.692,1.623-0.692 c0.692,0,1.229,0.226,1.605,0.675c0.377,0.45,0.565,0.948,0.565,1.496C18.397,8.13,18.385,8.404,18.36,8.598z" /></svg>');

  & + .input-group-append .btn-outline-light {
    border-left-color: $danger;
  }
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input.checked ~ .custom-control-label::before {
  color: $primary;
  border-color: $gray-500;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231b8658'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label::after {
  background-size: 100% 100%;
}

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input.checked ~ .custom-control-label::before {
    color: $primary;
    border-color: $gray-500;
    background-color: $secondary;
  }

  .custom-control-label {
    &::before, &::after {
      width: 1.5rem;
      height: 1.5rem;
      top: 0;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    .custom-control-label {
      &::before, &::after {
        width: 1rem;
        height: 1rem;
        top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
      }
    }
  }
}

.custom-checkbox .custom-control-input.checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.form-control-plaintext {
  outline: none;
}

select.form-control-plaintext,
select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
}

select.form-control-plaintext::-ms-expand,
select[disabled]::-ms-expand { //For IE
  display: none;
}

:not(.theme-dd-file-select) .form-control[type="file"] {
  height: auto;
}

/** Modal **/
.theme-dd-modal {

  .modal-header, .modal-footer {
    border: none;
  }

  .modal-header, .modal-footer > .row {
    justify-content: center;
  }

  .modal-footer {
    padding-left: 0;
    padding-right: 0;
  }

  .modal-content {
    padding: map-get($spacers, 2) map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 3);
    max-height: calc(100vh - 120px);
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
    border-top: $modal-header-border-width solid $modal-header-border-color;
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    overflow: hidden auto;
    max-height: calc(100% - 140px);

    &::-webkit-scrollbar, body::-webkit-scrollbar {
      width: 0.75rem;
      height: 0.75rem;
    }
    &::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
      background: $gray-400;
      border-radius: $border-radius-lg;
    }
    &::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
      background: $secondary;
      border-radius: $border-radius-lg;
    }

    & > .card + .card {
      margin-top: map-get($spacers, 3);
    }
  }

  .modal-title {
    font-weight: $font-weight-bold;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    .modal-content {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
      max-height: calc(100vh - 220px);
    }
  }
}

/** Alert **/
.alert-dismissible {
  padding-right: 6rem;

  .close {
    bottom: 0;
  }
}

/** Card **/
.card-body {
  padding: 0.75rem;
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    padding: $card-spacer-x;
  }
}

/** Error Page **/
.theme-dd-error-wrap {
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    & > .card-body {
      max-width: map-get($grid-breakpoints, lg);
    }
  }
}

.theme-dd-error-contact {
  margin-top: 2rem;
  background-color: $gray-100;

  &-message {
    font-size: $h6-font-size;
  }

  .btn {
    background-color: $gray-900;
    color: $white;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 5rem;
    
    &-message {
      font-size: $h4-font-size;
    }

    .btn {
      padding-right: 70px;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: $font-size-base;
    }

    .theme-dd-icon {
      width: auto;
      height: auto;
      top: inherit !important;
      right: 1.5rem !important;
      bottom: 0.5rem !important;
      transform: none !important;
    }
  }
}

.theme-dd-error-alert {
  background-color: $gray-100;
  &.is-invalid {
    position: relative;
    .card-body {
      padding-bottom: 3rem;
      background: url(../images/error/people.png) no-repeat 50% 95% / auto 3rem;
    }

    &::before {
      width: 48px;
      background: url(../images/error/invalid_arrow.png) no-repeat 0 0;
      content: "";
      height: 48px;
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    &.is-invalid .card-body {
      padding-bottom: 1.25rem;
      background-size: 664px 145px;
      background-position-y: 85%;
    }
  }
}

.theme-dd-error-h1 {
  font-size: $h2-font-size;
  font-weight: $font-weight-light;
  color: $pale-blue;
  font-family: $font-family-noto;

  & + p {
    font-size: $font-size-sm;
  }

  &::after {
    width: 2em;
    margin: 0.5rem auto;
    background-color: $gray-400;
    height: 3px;
    content: "";
    display: block;
  }
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    font-size: $h1-font-size;

    & + p {
      font-size: $font-size-base;
    }

    &::after {
      width: 2.5em;
      margin-top: 0.75em;
    }
  }
}

.theme-dd-error-balloon {
  background-color: $footer-logo-color;
  color: $white;
  border-radius: 4rem;
  position: relative;

  &::before {
    border-top: solid 1rem $footer-logo-color;
    border-left: solid 1rem transparent;
    border-right: solid 1rem transparent;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .theme-dd-howto-title {
    font-size: $font-size-base;
  }

  &.has-person {
    margin-top: 2em;
  }

  &.has-person .theme-dd-howto-title {
    &::before {
      width: 1em;
      height: 2em;
      background: url(../images/error/person.png) no-repeat 0 0 / 100% 100%;
      content: "";
      transform: none;
    }
    &::after {
      display: none;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    .theme-dd-howto-title {
      font-size: $h4-font-size;
    }

    &.has-person {
      margin-top: 8em;
    }

    &.has-person .theme-dd-howto-title {
      &::before {
        width: 96px;
        height: 185px;
        left: -50px;
        bottom: -1.5em;
      }
    }
  }
}

.theme-dd-error-case {
  &::after {
    width: 2em;
    margin: 0.5rem auto;
    background-color: $footer-logo-color;
    height: 3px;
    content: "";
    display: block;
  }
  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    &::after {
      margin-top: 1.25rem;
    }
  }
}

.theme-dd-error-causes {
  color: $footer-logo-color;
}

/** Contents **/
.theme-dd-howto {
  &-balloon, &-bg {
    background-color: $teal;
    color: $white;
  }

  &-badge {
    background-color: $yellow;
    font-size: $h4-font-size;
    color: $body-color;
    white-space: normal;
  }

  &-balloon {
    border-radius: 4rem;
    position: relative;

    &::before {
      border-top: solid 1rem $teal;
      border-left: solid 1rem transparent;
      border-right: solid 1rem transparent;
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-title {
    margin-bottom: 0;
    padding-left: $h4-font-size;
    padding-right: $h4-font-size;
    position: relative;

    &::before, &::after {
      position: absolute;
      bottom: -0.25rem;
      content: "／";
      font-size: 2rem;
    }

    &::before {
      left: -0.25rem;
      transform: scale(-1, 1);
    }

    &::after {
      right: -0.25rem;
    }
  }

  &-icon-wrap {
    width: 10rem !important;
    height: 10rem !important;
    @include icon-rounded($white, inherit);
  }

  &-wrap {
    background-color: $white;
    margin-top: map-get($spacers, 5);
    color: $body-color;

    ol {
      padding-left: map-get($spacers, 3);

      & > li::marker {
        color: $teal;
        font-weight: $font-weight-bold;
      }

      & > li:first-line {
        color: $teal;
        font-weight: $font-weight-bold;
      }
      
      & > li + li {
        margin-top: map-get($spacers, 2);
      }

      &.is-dark {
        & > li:first-line, & > li::marker {
          color: $black;
        }
      }
    }

    > .theme-dd-howto-badge {
      position: relative;
      top: -1rem;
    }

    .border.border-dark {
      border-width: 2px !important;
    }
  }

  &-step {
    color: $teal;
    font-weight: $font-weight-bold;
  }

  &-badge.is-inversed {
    background-color: $white;
    color: $yellow;
  }

  &-plan-table {
    thead th {
      border-top: none;
    }

    thead th:nth-of-type(3) {
      background-color: darken(adjust-hue($tangerine, 23), 1.57);
      position: relative;
      
      span {
        top: -0.25rem;
        position: relative;
      }

      &::before {
        width: 100%;
        background-color: darken(adjust-hue($tangerine, 23), 1.57);
        height: 0.5rem;
        content: "";
        position: absolute;
        top: -0.5rem;
        left: 0;
      }
    }

    thead th:nth-of-type(2n) {
      background-color: $yellow;
    }

    .theme-dd-icon-wrap {
      width: 1.75rem;
      height: 1.75rem;
      @include icon-rounded($tangerine, $white);
    }

    tbody {
      th, td {
        vertical-align: middle;
      }

      td {
        background-color: lighten(adjust-hue($yellow, 4), 35.49);
  
        &:nth-of-type(2) {
          background-color: lighten(adjust-hue($yellow, -8), 31.18);
        }
      }

      td:not(:nth-of-type(2)) {
        strong {
          color: darken(adjust-hue($yellow, -7), 6.27);
        }

        .theme-dd-icon-wrap {
          background-color: darken(adjust-hue($yellow, -7), 6.27);
        }
      }
    }

    .text-danger {
      color: darken(saturate(adjust-hue($danger, 2), 30.79), 14.90) !important;
    }

    .text-warning {
      color: $tangerine !important;
    }

    .forced-bold {
      $size: 0.5px;
      text-shadow: 
      $size $size 0, 
      $size -$size 0px,
        -$size $size 0,
        -$size -$size 0px,
        $size 0px 0px,
        0px $size 0px,
        -$size 0px 0px,
        0px -$size 0px;
      letter-spacing: 1px;
    }
  }

  @media print, screen and (min-width: map-get($grid-breakpoints, lg)) {
    &-title {
      padding: 0 map-get($spacers, 5);

      &::before, &::after {
        font-size: 2.5rem;
      }
      
      // &::before {
      //   left: -2rem;
      // }

      // &::after {
      //   right: -2rem;
      // }
    }

    &-image-wrap {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    &-text {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    &-block, &-wrap {
      max-width: 800px;
    }
  }

}

/** Others **/
.theme-dd-shadow {
  box-shadow: 0.1rem 0.1rem 0.1rem rgba($black, .15);

  &-blurred {
    box-shadow: 0 0 0.3rem rgba($black, .15);
  }
}

.theme-dd-no-hover {
  &.btn-secondary:hover {
    background-color: $secondary;
    border-color: $secondary;
    cursor: default;
  }
}

.theme-dd-bg-secondary,
.theme-dd-striped > div:nth-of-type(even),
.theme-dd-striped tbody tr:nth-of-type(even) {
  background-color: $pale-green;
}

.theme-dd-writing-rl {
  writing-mode: vertical-rl;
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    writing-mode: inherit;
  }
}

.theme-dd-pl-initial {
  @media print, screen and (min-width: map-get($grid-breakpoints, md)) {
    padding-left: 0.75rem !important;
  }
}

.theme-dd-sticky {
  position: sticky;
  top: $pc-header-height + 16;

  &-right {
    position: sticky;
    bottom: 0;
    right: 0;
    pointer-events: none;

    * {
      pointer-events: auto;
    }
  }
}