@charset 'utf-8';
// Body
$body-bg: #ebecf0;

// Media query
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 770px,
  lg: 992px,
  xl: 1200px
);

// Right Ads Width
$right-area-w: 338px;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@900&display=swap');
$font-family-sans-serif: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
$font-family-noto: 'Noto Sans JP', sans-serif;
$line-height-base: 1.6;

// Colors
$green: #1b8658;
$blue: #145c8c;
$indigo: #34495e;
$red: #de715a;
$orange: #f79428;
$yellow: #ffef1e;
$cyan: #3896dc;
$teal: #01bdaf;
$primary: $green;
$secondary: lighten(saturate(adjust-hue($green, 13), 12.79), 10.00);
$warning: $orange;

// $purple: #9561e2;
// $pink: #f66d9b;

// Original Colors
$footer-color: #262c30;
$footer-logo-color: lighten(desaturate(adjust-hue($green, -35), 32.56), 14.71);
$dark-green: darken(saturate($green, 21.19), 15.69);
$pale-green: lighten(saturate(adjust-hue($secondary, -7), 15.53), 56.47);
$pale-blue: lighten(desaturate(adjust-hue($blue, 16), 34.26), 42.16);
$tangerine: #ff6600;

// Buttons
//$btn-font-weight: 700;

// Form
$input-padding-y: .25rem;

// Options
$enable-responsive-font-sizes: true;

// header
$pc-header-height: 88px;
$pc-header-small-height: 56px;

// File Icon Size
$icon-size-sp: 28px;
$icon-size-pc: 40px;
